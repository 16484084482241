import React from "react";
import "./TokenLaunchComponent.css";
import CurvedButton from "../CurvedButton";
import tokenLaunchImage from "../../../static/token-launch.png";
import Fade from "../../animations/Fade";
import ButtonTransparent from "../ButtonTransparent";

export default function TokenLaunchComponent() {
  return (
    <div className="mx-8">
      <div className="launch flex justify-between">
        <Fade direction={"bottomToTop"} duration={1}>
          <p className="xs:hidden sm:hidden md:hidden">
            The Wait is Over <br />
            Algomint Governance Token Launches a Bridge to the future
          </p>
        </Fade>
        <div className="w-full px-8 xs:hidden sm:hidden md:hidden">
          <p className="my-2">AVAILABLE ON</p>
          <div className="flex space-x-2 my-2 ">
            <Fade direction={"bottomToTop"} duration={1}>
              <ButtonTransparent
                title={"Humble Swap"}
                url={"https://app.humble.sh/swap"}
                displayIcon={false}
                showBorder={true}
              />
            </Fade>
            <Fade direction={"bottomToTop"} duration={1}>
              <ButtonTransparent
                title={"Folks Finance"}
                url={"https://app.folks.finance"}
                displayIcon={false}
                showBorder={true}
              />
            </Fade>
            <Fade direction={"bottomToTop"} duration={1}>
              <ButtonTransparent
                title={"Pact"}
                url={"https://app.pact.fi/swap"}
                displayIcon={false}
                showBorder={true}
              />
            </Fade>
            <Fade direction={"bottomToTop"} duration={1}>
              <ButtonTransparent
                title={"Tinyman"}
                url={"https://app.tinyman.org/"}
                displayIcon={false}
                showBorder={true}
              />
            </Fade>
          </div>
        </div>
      </div>
      <Fade direction={"bottomToTop"} duration={1}>
        <img
          className="w-full bannerImage rounded-lg"
          src={tokenLaunchImage}
          alt="token-launch"
        />
      </Fade>
    </div>
  );
}
