import React from "react";
import BlogPostCard from "../BlogPostCard";
import "./BlogPostBanner.css";
import Fade from "../../animations/Fade";
import { Link } from "gatsby";

export default function BlogPostBanner({ posts }) {
  return (
    <Fade direction={"bottomToTop"} duration={1}>
      <div
        className="blog grid gap-y-6 gap-x-6 grid-cols-3 md:mx-20 lg:mx-20
          sm:mx-10 xs:mx-5 content-around"
      >
        {posts.length === 0 ? (
          <div>No post found</div>
        ) : (
          posts.map((item, index) => {
            return (
              index !== 0 && (
                <Link
                  key={item.guid}
                  to={`/articles/${item.title.replace(/#/g, "")}`}
                >
                  <BlogPostCard
                    title={item.title.toUpperCase()}
                    thumbnail={item.thumbnail}
                    pubDate={item.pubDate}
                    content={item.content}
                    btnName="Press Release"
                  />
                </Link>
              )
            );
          })
        )}
      </div>
    </Fade>
  );
}
