import React from "react";
import "./BlogPostCard.css";
import { formatDate, readingTime } from "../../utils/utils";

export default function BlogPostCard(props) {
  return (
    <div className="post-card mt-8 border rounded-md p-2 hover:opacity-50 transition-all">
      {props.thumbnail ? (
        <img className={props.style} src={props.thumbnail} alt="blog-image" />
      ) : (
        <img className={"h-16 w-16"} src={"/algomint.gif"} alt="blog-image" />
      )}

      <h4 className="my-6">{props.title}</h4>
      <div className="flex justify-between">
        <span>
          Read time{" "}
          <span className="text-accentColor">{readingTime(props.content)}</span>{" "}
        </span>
        <span className="text-accentColor">{formatDate(props.pubDate)}</span>
      </div>
      <button
        type="button"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        className="press-release-btn"
      >
        {props.btnName}
      </button>
    </div>
  );
}
