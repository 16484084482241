export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const readingTime = (content) => {
  const wordCount = content.replace(/[^\w ]/g, "").split(/\s+/).length;
  let readingTimeInMinutes = Math.floor(wordCount / 228) + 1;
  let readingTimeAsString = readingTimeInMinutes + " min";
  return readingTimeAsString;
};

export const getEquivalentAssetName = (assetName) => {
  switch (assetName) {
    case "goBTC":
      return "BTC equivalent";
      break;
    case "goETH":
      return "ETH equivalent";
      break;
    case "goUSD":
      return "USDC equivalent";
      break;
    default:
      return;
  }
};
