import React from "react";
import "./MediaBanner.css";
import Fade from "../../animations/Fade";
import MediaAlert from "../mediaAlert";
export default function MediaBanner() {
  return (
    <div>
      <MediaAlert />
      <div className="mt-60">
        <Fade direction={"leftToRight"} duration={1}>
          <h1 className="heading text-2xl">Algomint media</h1>
        </Fade>
        <div className="banner content-between py-16">
          <Fade direction={"bottomToTop"} duration={1}>
            <p className="text-base">
              A consolidated look at all things Algomint. This is the place for
              updates, upcoming releases and news spanning from Minting GoBTC to
              the Algorand DeFi experience.
            </p>
          </Fade>
          <Fade direction={"bottomToTop"} duration={1}>
            <p className="year text-base xs:hidden sm:hidden">Algomint 2022</p>
          </Fade>
        </div>
      </div>
    </div>
  );
}
