import "./ButtonTransparent.css";
import React from "react";

export default function buttonTransparent({
  title,
  url,
  displayIcon,
  styles,
  height,
  width,
  showBorder,
  borderColor,
  borderColoronHover,
  textColor,
}) {
  return (
    <button
      className={`buttonTransparent-wrapper ${
        showBorder
          ? `rounded-full ${
              borderColor ? `border-${borderColor}` : "border-[#e8e8e8]"
            } border-0.5 hover:border-0.5 hover:${
              borderColoronHover
                ? `border-${[borderColoronHover]}`
                : "border-[#1cffd6]"
            }`
          : ""
      } 
      ${textColor ? `text-${textColor}` : "text-[#e8e8e8]"}
      ${height ? `h-${height}` : "h-8 xs:h-6 sm:h-6"}  
      ${width ? `w-${width}` : "w-36 xs:w-24 sm:w-28"}
       flex ${styles ? styles : ""} cursor-pointer `}
      onClick={() => {
        window.open(url);
      }}
    >
      {displayIcon ? (
        <img
          src="/icons/download.svg"
          alt="download-icon"
          className="h-6 w-6"
        />
      ) : (
        ""
      )}

      <span className="buttonTransparent-title ml-2 xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md">
        {title}
      </span>
    </button>
  );
}
