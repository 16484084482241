import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./MediaAlert.css";

export default function MediaAlert() {
  const [show, setShow] = useState(true);
  const handleHide = () => setShow(false);

  const { toggle } = useSelector((state) => state.toggle);

  return (
    <div>
      {show && (
        <div
          className={`${
            toggle
              ? "hidden"
              : `block alert-card mx-0 alert bg-red-100 rounded-lg py-5 px-6 mb-3 mt-10 text-base text-yellow-700 flex items-center
         w-full alert-dismissible fade show `
          }`}
          role="alert"
        >
          <p className="w-3/12"></p>
          <a className="alert-text" href="/token-launch">
            {" "}
            GOMINT TOKEN AVAILABLE ON HUMBLE SWAP,FOLKS FINANCE, PACT AND
            TINYMAN. VIEW DETAILS →{" "}
          </a>
          <button
            type="button"
            className="close-button btn-close box-content w-4 h-4 p-1 ml-auto text-yellow-900 border-none 
        rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-yellow-900 
        hover:opacity-75 hover:no-underline"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={handleHide}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
}
