import React, { useState, useEffect } from "react";
import BlogPostBanner from "../components/BlogPostBanner";
import MediaBanner from "../components/MediaBanner";
import TokenLaunchBanner from "../components/TokenLaunchComponent";
import BlogPostTitleBanner from "../components/BlogPostTitleBanner";
import { fetchBlog } from "../api/blogpost";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import MediaAlert from "../components/mediaAlert";
import { navigate } from "gatsby";

export default function Media() {
  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    const blogList = await fetchBlog();
    setPosts(blogList);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div>
      <Layout BackgroundColor={"[#121212]"}>
        <Helmet>
          <title>Algomint | Media</title>
          <script>
            {`
              !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('phc_igoJY4YFsqvSyr3ZG8RCPw8bzWMMtumGFJsylr0T9bz', {api_host: 'https://app.posthog.com'})
            `}
          </script>
        </Helmet>
        <div className="mx-8">
          <MediaBanner />
        </div>
        <div className="xs:my-0 sm:my-0 my-64 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-6 hover:opacity-75">
          {posts.length ? <BlogPostTitleBanner post={posts[0]} /> : <></>}
        </div>
        <div
          className="xs:my-0 sm:my-0 my-64 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-6 hover:opacity-75"
          onClick={() => {
            navigate("/token-launch");
          }}
        >
          <TokenLaunchBanner />
        </div>
        <BlogPostBanner posts={posts} />
      </Layout>
    </div>
  );
}
