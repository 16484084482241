import React, { useState } from 'react'
import axios from 'axios'

export const fetchBlog = async () => {

  const url = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@algomint'
  try{
    const response = await axios.get(url)
    const data = response.data.items
    const posts = data.filter(item => item.categories.length > 0)
    console.log(posts)
    return posts
  }catch(err){
    console.log(err)
  }
}