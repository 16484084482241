import React from "react";
import { Link } from "gatsby";
import "./BlogPostTitleBanner.css";
import algomintLogo from "../../../static/algomint_white_logo.svg";
import { formatDate, readingTime } from "../../utils/utils";
import Fade from "../../animations/Fade";

export default function USDBasketComponent({ post }) {
  const postDate = new Date(post.pubDate);
  const postMonthName = postDate.toLocaleString("en-US", { month: "long" });
  return (
    <Link key={post.guid} to={`/articles/${post.title.replace(/#/g, "")}`}>
      <div className="mx-8 rounded-lg basket-wrapper">
        <Fade direction={"bottomToTop"} duration={1}>
          <div className="rounded-lg flex flex-col justify-evenly h-96 w-full media-header-wrapper">
            <div className="flex flex-row justify-end mr-2">
              <button
                type="button"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="release-btn"
              >
                Breaking News
              </button>
            </div>
            <div className="flex flex-row justify-center">
              <img
                src={algomintLogo}
                className="xs:w-36  sm:w-56  md:w-56 lg:w-56 xl:w-56 2xl:w-56"
                alt="algomint-logo"
              />
            </div>
            <div className="text-center media-title-banner xs:text-sm sm:text-sm lg:text-lg xl:text-xl 2xl:text-2xl">
              {" "}
              {post.title}
            </div>
            <div className="w-full flex flex-row justify-center">
              <button className="rounded-full bg-grey p-4 uppercase tracking-wide xs:text-xs sm:text-sm md:text-md lg:text-lg xl:text-2xl 2xl:text-2xl">
                {postMonthName}
              </button>
            </div>
          </div>
          <div className="uppercase">{post.title}</div>
          <div className="w-full flex flex-row xs:flex-col  justify-between">
            <div>Read time {readingTime(post.content)}s</div>
            <div>{formatDate(post.pubDate)}</div>
          </div>
        </Fade>
      </div>
    </Link>
  );
}
